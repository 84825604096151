
.half-screen {
    width: 50%;
    height: 100%;
  }

.mobile-humdrum-text {
  transform: rotate(90deg);
  color: #ed5f5f;
  font-family: Humdrum;
  font-size: 60vw;
  position: relative;
  top: 20;
  right: 0;
  z-index: 2;
  letter-spacing: 1.3vh;
}

.square-mobile {
    position: absolute;
    right: 0;
    bottom: 20;
    width: 60%;
    transform: rotate(15deg);
    -webkit-transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
}

.spin-mobile{
    -ms-transform: rotate(160deg);
    -webkit-transform: rotate(160deg);
    transform: rotate(160deg);
    transition: transform 1s ease-in-out;
}