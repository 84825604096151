.home-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 38vw;
  transition: background-color 1s;
}

.home-opaque {
  background-color: rgba(241, 241, 239, 0.95);
}
.square-wrapper{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: sticky;
  top: -57vh;
  pointer-events: none; 
  z-index: 10;
}

.spin{
  transform: rotate(-4deg); /* Equal to rotateZ(45deg) */
}

.square{
  width: 100%;
  transition: transform 1s;
}

.menu-wrapper {
  width: 36vw;
  height: 36vw;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-sticky{
  width: 36vw;
  position: sticky;
  /* top: 100px; */
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto; 
}

.options-wrapper {
  height: 4vh;
  margin-right: .35vw;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ed5f5f;
  font-size: 1.25vw;
  background-color: rgba(0, 0, 0, .6);
}

.header-text {
  color: #ed5f5f;
  font-family: Humdrum;
  font-size: 10.8vw;
  letter-spacing: 10px;
  width: 80%;
  line-height: 10vw;
}

.pointer{
  cursor: pointer;
}

.menu-option {
  cursor: pointer;
  font-family: Humdrum;
  color: #619fd3;
  font-size: 2vw;
  line-height: 2vw;
  letter-spacing: .35vw;
  text-transform: uppercase;
}

.menu-option:hover{
  color: lightpink;
}

.circle{
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: #61d3ac;
  margin-top: .5vh;
  margin-right: .7vh;

}
.sections-wrapper{
  font-family: 'Courier';
  margin-bottom: 10px;
}

.separation-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
