
   .background{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	/* perspective: 1px;
	-webkit-perspective: 1px;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d; */
    /* overflow-x: hidden;
    overflow-y: hidden;*/
    display: flex; 
    justify-content: center; 
    align-items: center;
    /* perspective: 1px;
    perspective-origin: 0 0; */
  }
  
  .floating-shapes {
    width: 100%;
    border: 1px solid red;
    height: 4000px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    transform-origin: 0 0;
    transform: translateZ(-1px) scale(5)
  }
  
  /* .square{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  } */
  
  .content {
    /* border: 1px solid red; */
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* right: 0; */
    /* justify-content: center;
    transform-origin: 0 0;
    transform: translateZ(0px) scale(1); */
    /* justify-content: center; */
    width: 100vw;
	height: 100vh;
  margin-left: 27%;
	/* position: relative;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d; */

  }

  .socials-container{
    position: sticky;
    width: 100px;
    height: 100vh;
    top: 0px;
    left: 0px;
  }