.container{
    display: flex;
    flex-direction: column;
    overflow:hidden;
    margin-left: -50px;
}

.photo{
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.photo img{
    width: 100%;
    height: auto
}

.caption{
    align-self: flex-end;
    font-size: 12px;
    color: black;
}