
.listen-container{
    padding-top: 29vh;
    min-height: 100vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listen-header-text{
    /* writing-mode: vertical-rl;
  text-orientation: sideways-right; */
  text-transform: uppercase;
  font-size: 13vw;
  letter-spacing: 2vh;
  width: 11vw;
  line-height: 8vh;
  /* line-height: 25vh; */
  color: black;
  z-index: 2;
  font-family: Humdrum;
}