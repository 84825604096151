.App {
  text-align: center;
}

html {
  overflow: hidden;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  display: none;
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
