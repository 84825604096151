.video-container {
    padding-top: 29vh;
    height: 200vh;
    display: flex;
    flex-direction: column;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }
  
  .video-header-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  
  .video-header-text{
    text-transform: uppercase;
    font-family: Humdrum;
    font-size: 13vw;
    letter-spacing: 2vh;
    width: 11vw;
    line-height: 14vh;
    z-index: 2;
  }
  